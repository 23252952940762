const colors = "color: #fff;background: #1EAEFD; padding: 0 0.5em 0 0; border-radius:1em; font-size: 1em;";

export default class Logger /* implements Console */ {
    #logger;
    #name;

    constructor(logger, name) {
        if (!logger) {
            logger = console;
        }
        this.#logger = logger;
        this.#name = ["nuglif"];

        if (name) {
            if (!Array.isArray(name)) {
                name = [name];
            }
            this.#name = [this.#name, ...name];
        }
        this.#name = this.#name.join(" > ");
    }

    assert() {
        let params = Array.from(arguments);
        this.#logger.assert.apply(this.#logger, params);
    }

    clear() {
        let params = Array.from(arguments);
        this.#logger.clear.apply(this.#logger, params);
    }

    count() {
        let params = Array.from(arguments);
        this.#logger.count.apply(this.#logger, params);
    }

    countReset() {
        let params = Array.from(arguments);
        this.#logger.countReset.apply(this.#logger, params);
    }

    debug() {
        let params = Array.from(arguments);
        this.#logger.debug.apply(this.#logger, [
            "%c🟣 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }

    dir() {
        let params = Array.from(arguments);
        this.#logger.dir.apply(this.#logger, params);
    }

    dirxml() {
        let params = Array.from(arguments);
        this.#logger.dirxml.apply(this.#logger, params);
    }

    error() {
        let params = Array.from(arguments);
        this.#logger.error.apply(this.#logger, [
            "%c🔴 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }

    group() {
        let params = Array.from(arguments);
        this.#logger.group.apply(this.#logger, [
            "%c🟤 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }

    groupCollapsed() {
        let params = Array.from(arguments);
        this.#logger.groupCollapsed.apply(this.#logger, [
            "%c🟤 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }

    groupEnd() {
        let params = Array.from(arguments);
        this.#logger.groupEnd.apply(this.#logger, params);
    }

    info() {
        let params = Array.from(arguments);
        this.#logger.info.apply(this.#logger, [
            "%c🔵 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }

    log() {
        let params = Array.from(arguments);
        this.#logger.log.apply(this.#logger, [
            "%c🟢 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }

    table() {
        let params = Array.from(arguments);
        this.#logger.table.apply(this.#logger, params);
    }

    time() {
        let params = Array.from(arguments);
        this.#logger.time.apply(this.#logger, params);
    }

    timeEnd() {
        let params = Array.from(arguments);
        this.#logger.timeEnd.apply(this.#logger, params);
    }

    timeLog() {
        let params = Array.from(arguments);
        this.#logger.timeLog.apply(this.#logger, params);
    }

    timeStamp() {
        let params = Array.from(arguments);
        this.#logger.timeStamp.apply(this.#logger, params);
    }

    trace() {
        let params = Array.from(arguments);
        this.#logger.trace.apply(this.#logger, [
            "%c🔘 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }

    warn() {
        let params = Array.from(arguments);
        this.#logger.warn.apply(this.#logger, [
            "%c🟡 " + this.#name + "%c " + params.shift(),
            colors,
            "",
            ...params,
        ]);
    }
}
