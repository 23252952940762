/**
 * @deprecated
 */
export default class User {

    /** @returns {string} */
    static get ANONYMOUS_UUID_COOKIE_NAME() {
        return "LPUID";
    }

    /** @returns {number} */
    static get UUID_COOKIE_EXPIRATION_DAYS() {
        return 3650;
    }

    /**
   * @private
   * @returns {string}
   */
    static get COOKIE_DOMAIN() {
        const hostName = window.location.hostname;
        return "." + hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    }

    /**
   * @param deviceInstanceIdManager {DeviceInstanceIdManager}
   */
    constructor(deviceInstanceIdManager) {
        this.deviceInstanceIdManager = deviceInstanceIdManager;
        this.anonymousUUID = this.getAnonymousUUID();
    }

    /** @type {DeviceInstanceIdManager} */
    deviceInstanceIdManager;

    /** @type {string} */
    anonymousUUID;

    /** @type {string} Logged-in uuid */
    loggedUUID;

    /**
   * @public
   * @returns {string|null}
   */
    get anonymousUUID() {
        if (this.anonymousUUID) {
            return this.anonymousUUID;
        }

        return null;
    }

    /**
   * @public
   * @returns {string|null}
   */
    get loggedInUUID() {
        if (this.loggedUUID) {
            return this.loggedUUID;
        }

        return null;
    }

    set loggedInUUID(loggedUUID) {
        this.loggedUUID = loggedUUID;
    }

    /**
   * @public
   * @returns {boolean}
   */
    get isLoggedIn() {
        return !!(this.loggedUUID);
    }

    getAnonymousUUID() {
        let UUID = null;

        this.deviceInstanceIdManager.get(function(id) {
            UUID = id;
        });

        return UUID;
    }
}
