
import HandlerAbstract from "./HandlerAbstract";
import LinkClickedParamBuilder from "../LinkClickedParamBuilder";

export default class ClickLinkHandler extends HandlerAbstract {

    constructor() {
        super("a[href]");
    }

    getEventHandlers() {
        return ["mouseup"];
    }

    _buildLinkClickedParam(target) {
        return new LinkClickedParamBuilder()
            .setUrl(target.href)
            .setLabel(this.getTargetLabel(target))
            .setLocation(this.getTargetLocation(target))
            .setType(this.getTargetType(target))
            .setObjectId(this.getTargetObjectId(target))
            .setPosition(this.getTargetPosition(target))
            .build();
    }

    handle(evt) {

        const target = evt.target.closest("a");

        if (target.dataset.tracking === "false") {
            return false;
        }

        let linkClicked = this._buildLinkClickedParam(target);
        this._analytics.pubSub.publish("linkClicked",linkClicked);
        return false;
    }

    getTargetObjectId(target) {

        if (target.closest("[data-target-id]")) {
            return target.closest("[data-target-id]").dataset.targetId;
        }

        return "";
    }

    getTargetLocation(target) {
        return target.closest("[data-location]").dataset.location + this.getTargetTimeframe(target);
    }

    getTargetType(target) {

        if (target.closest("[data-target-type]")) {
            return target.closest("[data-target-type]").dataset.targetType;
        }

        return "";
    }

    getTargetPosition(target) {

        if (target.closest("[data-position]")) {
            return target.closest("[data-position]").dataset.position;
        }

        return "";
    }

    getTargetTimeframe(target) {

        if (target.closest("[data-timeframe]")) {
            return this.capitalize(target.closest("[data-timeframe]").dataset.timeframe);
        }

        return "";
    }

    capitalize(string) {
        return string.substring(0, 1).toUpperCase() + string.substring(1);
    }
}
