
import HandlerAbstract from "./HandlerAbstract";

export default class ButtonClickHandler extends HandlerAbstract {

    getEventHandlers() {
        return ["mouseup"];
    }

    handle(evt) {
        const target = evt.target;
        const code = target.closest("[data-action-code]").dataset.actionCode;

        let params = null;
        const paramsElement = target.closest("[data-action-params]");
        if (paramsElement && paramsElement.dataset.actionParams) {
            params = JSON.parse(paramsElement.dataset.actionParams);
        }

        this._analytics.pubSub.publish("buttonClicked", {
            "label": this.getTargetLabel(target),
            "location": target.closest("[data-location]").dataset.location,
            "actionCode": code,
            "actionParams": params,
        });
        return false;
    }
}
