import Cookies from "js-cookie";
import sha1 from "sha1";
import uuidv1 from "uuid/v1";

export default class DeviceInstanceIdManager {

    /** @returns {string} */
    static get DEVICE_INSTANCE_ID_COOKIE() {
        return "LPUID";
    }

    /** @returns {string} */
    static get DEVICE_INSTANCE_ID_STORAGE_KEY() {
        return "lp.deviceInstanceId";
    }

    constructor() {
        this._id = null;
    }

    /**
     * NOTE: This method is formed in a way that it could eventually be async.
     *       The consumer of this class has to assume that the value may not always be instantly available.
     *
     *       I expect that we will eventually need to perform a server call to read a secured, server-side, http-only cookie.
     */
    get = (callbackFn) => {
        if (this._id === null) {
            this._load();
        }

        callbackFn(this._id);
    };

    getIdHashFromCache() {
        return sha1(this._loadIdFromLocalStorage());
    }

    _load = () => {
        // logic:
        //      - local storage is the most secure way to store information in a browser
        //      - cookies can be used to share value across subdomains.  not local storage
        //      - Javascript Cookies may not live long.  As browser security increase, it will become less and less reliable to store information using client-side cookies.
        //
        //  Algorithm:
        //      - Load Device Instance Id from local storage
        //      - if not available, perhaps the user has used another of our subdomains and the value is still available in a cookie.
        //        so try reading the Device Instance Id from a cookie
        //        **  Here we could eventually perform a server side http call to generate an HTTP-Only Secured Server Cookie and return the value.
        //            this way it could be a long-lived cookie with cross sub-domain compatibility.
        //            Since a server call may take too long, when we do it, we should do it asynchronously.
        //
        //      - if device instance id not available through a cookie, then we need to generate one.
        //      - once we have a DeviceInstanceId we make sure to store it in local storage for qui retrieval
        //        and update the cookie.
        console.debug("DID: load");
        let value = this._loadIdFromLocalStorage();
        if (value == null) {
            console.debug("DID: Failed to load from local storage, trying from cookie");

            // this situation may happen if when we have an ID from a subdomain and navigate to another subdomain.
            // we use the cookie attached to the top domain to transition it between subdomains.
            value = this._loadIdFromCookie();
            if (!value) {
                console.debug("DID: Failed to load from cookie, generating a new id.");
                value = this._createNewId();
            } else {
                console.debug("DID: recovered from cookie: " + value);
            }

            this._updateLocalStorage(value);
        }

        // always update the cookie to slide its expiration.
        this._updateCookie(value);

        this._id = value;
    };

    _loadIdFromLocalStorage = () => {
        return window.localStorage.getItem(DeviceInstanceIdManager.DEVICE_INSTANCE_ID_STORAGE_KEY);
    };

    _loadIdFromCookie = () => {
        return Cookies.get(DeviceInstanceIdManager.DEVICE_INSTANCE_ID_COOKIE);
    };

    _createNewId = () => {
        return uuidv1();
    };

    _getCookieDomain = () => {
        const hostName = window.location.hostname;
        return "." + hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    };

    _updateCookie = (newValue) => {
        console.debug("DID: update cookie");
        Cookies.set(DeviceInstanceIdManager.DEVICE_INSTANCE_ID_COOKIE, newValue, {
            expires: 3650,
            domain: this._getCookieDomain()
        });
    };

    _updateLocalStorage = (newValue) => {
        console.debug("DID: store");
        window.localStorage.setItem(DeviceInstanceIdManager.DEVICE_INSTANCE_ID_STORAGE_KEY, newValue);
    };
}
