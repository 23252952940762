
export default class LinkClickedParamBuilder
{
    /**
     * @type {string}
     * @private
     */
    _url = null;

    /**
     * @type {string}
     * @private
     */
    _label = null;

    /**
     * @type {string}
     * @private
     */
    _location = null;

    /**
     * @type {string}
     * @private
     */
    _type = null;

    /**
     * @type {string}
     * @private
     */
    _objectId = null;

    /**
     * @type {int}
     * @private
     */
    _position = null;

    /**
     * @type {string}
     * @private
     */
    _eventAction = null;

    /**
     * @param {string} value
     * @throws Error
     * @returns {LinkClickedParamBuilder}
     */
    setUrl(value) {
        if (typeof value === "string" && value !== "") {
            this._url = value;
        } else {
            throw Error("Invalid parameter value for url (given : " + value + ")");
        }
        return this;
    }

    /**
     * @param {string} value
     * @throws Error
     * @returns {LinkClickedParamBuilder}
     */
    setLabel(value) {
        if (typeof value === "string" && value !== "") {
            this._label = value.trim();
        } else {
            throw Error("Invalid parameter value for label (given : " + value + ")");
        }
        return this;
    }

    /**
     * @param {string} value
     * @throws Error
     * @returns {LinkClickedParamBuilder}
     */
    setLocation(value) {
        if (typeof value === "string" && value !== "") {
            this._location = value;
        } else {
            throw Error("Invalid parameter value for location (given : " + value + ")");
        }
        return this;
    }

    /**
     * @param {string} value
     * @throws Error
     * @returns {LinkClickedParamBuilder}
     */
    setType(value) {
        if (typeof value === "string") {
            this._type = value || null;
        } else {
            throw Error("Invalid parameter value for type (given : " + value + ")");
        }
        return this;
    }

    /**
     * @param {string} value
     * @throws Error
     * @returns {LinkClickedParamBuilder}
     */
    setObjectId(value) {
        if (typeof value === "string" || typeof value === "number") {
            this._objectId = value.toString() || null;
        } else {
            throw Error("Invalid parameter value for objectId (given : " + value + ")");
        }
        return this;
    }

    /**
     * @param {string|int} value - if string is given it will be parsed to int
     * @throws Error
     * @returns {LinkClickedParamBuilder}
     */
    setPosition(value) {
        let intValue = parseInt(value, 10);
        if (intValue >= 1) {
            this._position = intValue;
        } else if (value === "") {
            this._position = null;
        } else {
            throw Error("Invalid parameter value for position (given : " + value + ")");
        }
        return this;
    }

    /**
     * @param {string} value
     * @returns {LinkClickedParamBuilder}
     */
    setEventAction(value) {
        this._eventAction = value;

        return this;
    }

    /**
     * @throws Error
     * @returns {object}
     */
    build(keys = {
        url: true,
        label: true,
        location: true,
        type: true,
        objectId: true,
        position: true
    }) {
        let url = keys.url ? this._getUrl() : null;
        let label = keys.label ? this._getLabel() : null;
        let location = keys.location ? this._getLocation() : null;
        let type = keys.type ? this._getType() : null;
        let objectId = keys.objectId ? this._getObjectId() : null;
        let position = keys.position ? this._getPosition() : null;
        let eventAction = this._getEventAction();

        return {
            url,
            label,
            location,
            type,
            objectId,
            position,
            eventAction
        };
    }

    /**
     * @returns {string}
     * @throws Error
     * @private
     */
    _getUrl() {
        if (this._url === null) {
            throw Error("Invalid parameter : url must be set");
        }

        return this._url;
    }

    /**
     * @returns {string}
     * @throws Error
     * @private
     */
    _getLabel() {
        if (this._label === null) {
            throw Error("Invalid parameter : label must be set");
        }

        return this._label;
    }

    /**
     * @returns {string}
     * @throws Error
     * @private
     */
    _getLocation() {
        if (this._location === null) {
            throw Error("Invalid parameter : location must be set");
        }

        return this._location;
    }

    /**
     * @returns {string}
     * @throws Error
     * @private
     */
    _getType() {
        if (this._type === null) {
            return "undefined";
        } else if (this._type === "story" && this._objectId === null) {
            throw Error("Invalid parameter : objectId is not set");
        }
        return this._type;
    }

    /**
     * @returns {string}
     * @throws Error
     * @private
     */
    _getObjectId() {
        if (this._type === null) {
            return null;
        }
        return this._objectId;
    }

    /**
     * @returns {int}
     * @throws Error
     * @private
     */
    _getPosition() {
        return this._position;
    }

    /**
     * @returns {?string}
     * @private
     */
    _getEventAction() {
        return this._eventAction || "linkClicked";
    }

}
