const MODULE_DATA_ATTR_SUFFIX = "lazy-module";
const MODULES = {
    "photoGallery": () => import("./PhotoGalleryModule"),
    "quote": () => import("./QuoteModule"),
    "mobileNavigation": () => import("./MobileLayoutModule"),
    "genericLayout": () => import("./GenericLayoutModule"),
    "trending": () => import("./TrendingModule"),
    "homeSection": () => import("./HomeSectionModule"),
    "homeStats": () => import("./HomeStatsModule"),
    "authorPageStats": () => import("./AuthorPageStatsModule"),
};

export const ALL_MODULES = 1;
export const DESKTOP_MODULES = 2;

export default class ModuleManager {

    _analyticsManager;

    constructor(window, analyticsManager) {
        this._domWindow = window;
        this._analyticsManager = analyticsManager;
    }

    load() {
        const document = this._domWindow.document;

        let modulesToLoad;
        let modulesInPage = Array.from(document.querySelectorAll("[data-" + MODULE_DATA_ATTR_SUFFIX + "]"))
            .map(node => node.getAttribute("data-" + MODULE_DATA_ATTR_SUFFIX));

        // remove duplicate entries
        modulesToLoad = modulesInPage.filter((el, id) => {
            return (id === 0 || modulesInPage.slice(0, id).indexOf(el) === -1);
        });
        modulesToLoad = modulesToLoad.filter(moduleName => {
            const moduleExists = MODULES[moduleName] !== undefined;
            if (!moduleExists) {
                console.warn("unknown module '" + moduleName + "'");
            }

            return moduleExists;
        });
        modulesToLoad.forEach(moduleName => {
            (MODULES[moduleName]())
                .then(esModule => esModule.default)
                .then(moduleBuilder => moduleBuilder(this._domWindow, this._analyticsManager));
        });
    }
}
