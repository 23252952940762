import Anchor from "../Anchor/Anchor";

export default class ClickTracker {

    /**
     * @private
     * @member {User}
     */
    _user;

    /**
     * @param {Window} domWindow
     * @param {User} user
     */
    constructor(domWindow, user) {
        this._user = user;
        domWindow.addEventListener("mouseup", (event) => {

            const element = event.target;

            const htmlAnchorElement = this._getHtmlAnchorElement(element);
            if (htmlAnchorElement) {
                this._handleHtmlAnchorElement(htmlAnchorElement);
            }
        });
    }

    /**
     * @param {HTMLAnchorElement} element
     * @private
     */
    _handleHtmlAnchorElement(element) {
        new Anchor(element, this._user);
    }

    /**
     * @param {Element} element
     * @returns {null|Element}
     * @private
     */
    _getHtmlAnchorElement(element) {
        if ("function" !== typeof element.closest) {
            return null;
        }

        return element.closest("a");
    }
}
