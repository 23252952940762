import debounce from "lodash.debounce";
import {detect} from "detect-browser";

export default class NavigationLayout {

    /** @type Document */
    _domDocument;

    /** @type Window */
    _domWindow;

    /** @type HTMLElement */
    _mainHeader;

    /** @type number */
    _mainHeaderHeight;

    /** @type HTMLElement */
    _stickyMenuTriggerElement;

    constructor(domDocument, domWindow) {
        this._domDocument = domDocument;
        this._domWindow = domWindow;
    }

    boot() {
        this._bootStickyMenu();
        this._bootSearch();
        this._bootSearchMenuToggle();
        this._bootServiceMenu();
        this._bootUserAccountMenu();

        // @todo this workaround should be removed when breakpoints will be correctly implemented on story page
        if (document.querySelector("div.main") && getComputedStyle(document.querySelector("div.main")).minWidth === "1170px") {
            this._domWindow.addEventListener("resize", debounce(this._tempAdjustWidth.bind(this), 15));
            this._tempAdjustWidth();
        }
    }

    _bootStickyMenu() {
        this._mainHeader = this._domDocument.querySelector("body > header");
        if (this._mainHeader) {
            this._mainHeaderHeight = this._mainHeader.clientHeight;
            this._stickyMenuTriggerElement = this._getStickyMenuTriggerElement();
            this._domWindow.addEventListener("scroll", debounce(this._handleMenuScroll.bind(this), 15));
        }
    }

    _tempAdjustWidth() {
        if (this._domWindow.innerWidth <= 1185) {
            // margin-left + elm width + margin-right + col width
            this._mainHeader.style.width = (10 + document.querySelector("div.main").offsetWidth + 30 + 300) + "px";
            document.querySelector("footer.footer").style.width = this._mainHeader.style.width;
        } else {
            this._mainHeader.style.width = "auto";
            document.querySelector("footer.footer").style.width = "auto";
        }

        if (this._domWindow.innerWidth <= 1170 || ((detect().os.toLowerCase()) === "mac os" && this._domWindow.innerWidth === 1194)) {
            var w = 1170;
            if ((detect().os.toLowerCase()) === "mac os" && (this._domWindow.innerWidth === 1194)) {
                w = 1194;
            }
            this._mainHeader.style.width = w + "px";
            document.querySelector("footer.footer").style.width = this._mainHeader.style.width;
        }
    }

    _handleMenuScroll() {
        if (this.shouldDisplayStickyMenu()) {
            this._mainHeader.style.paddingTop = `${this._mainHeaderHeight}px`;
            this._mainHeader.classList.add("mainHeader--sticky");
        } else {
            this._mainHeader.style.paddingTop = 0;
            this._mainHeader.classList.remove("mainHeader--sticky");
        }
    }

    _getStickyMenuTriggerElement() {
        const elements = this._domDocument.querySelectorAll("[data-sticky-menu-trigger]");
        const elementsLength = elements.length;

        if (elementsLength) {
            return elements[elementsLength - 1];
        }

        console.warn("Cannot find sticky menu trigger!");
    }

    shouldDisplayStickyMenu() {
        if (window.innerWidth < 1024)
            return false;

        if (this._stickyMenuTriggerElement) {
            return this._stickyMenuTriggerElement.getBoundingClientRect().top <= 0;
        }

        return this._domWindow.scrollY > this._mainHeaderHeight;
    }

    _bootServiceMenu() {
        const toggleTriggerElement = this._domDocument.querySelector(".mainNav__serviceItem");
        const toggledElement = this._domDocument.querySelector(".servicesMenu");
        if (toggleTriggerElement && toggledElement) {
            this._domDocument.addEventListener("click", (e) => {
                if (toggledElement.contains(e.target)) {
                    return;
                }
                if (toggleTriggerElement.contains(e.target)) {
                    toggleTriggerElement.classList.toggle("mainNav__serviceItem--state-open");
                } else {
                    toggleTriggerElement.classList.remove("mainNav__serviceItem--state-open");
                }
            });
        }
    }

    _bootSearchMenuToggle() {
        const toggleTriggerElement = this._domDocument.querySelector(".mainNav__searchItem");
        const toggledElement = this._domDocument.querySelector(".mainNav__searchMenu");
        const searchInputElem = this._domDocument.querySelector(".mainNav__search__input");
        if (toggleTriggerElement && toggledElement && searchInputElem) {
            this._domDocument.addEventListener("click", (e) => {
                if (toggledElement.contains(e.target)) {
                    return;
                }
                if (toggleTriggerElement.contains(e.target)) {
                    toggleTriggerElement.classList.toggle("mainNav__searchItem--state-open");
                    searchInputElem.focus();
                } else {
                    toggleTriggerElement.classList.remove("mainNav__searchItem--state-open");
                }
            });
        }
    }

    _bootSearch() {
        const searchInputElem = this._domDocument.querySelector(".mainNav__search__input");
        const searchMenu = this._domDocument.querySelector(".mainNav__searchMenu");
        const isInputValid = () => {
            if (searchInputElem.value.length) {
                searchMenu.classList.add("mainNav__searchMenu--valid");
            } else {
                searchMenu.classList.remove("mainNav__searchMenu--valid");
            }
        };

        if (searchInputElem && searchMenu) {
            isInputValid();
            searchInputElem.addEventListener("keyup", isInputValid);
        }
    }

    _bootUserAccountMenu() {
        const { lpauth } = window;
        const toggleTriggerElement = this._domDocument.querySelector(".mainNav__userAccountItem");
        const toggledElement = this._domDocument.querySelector(".userAccountWidget__popin");

        if (toggleTriggerElement && toggledElement) {
            if (lpauth && !lpauth.isAuthReady()) {
                toggleTriggerElement.classList.add("mainNav__userAccountItem--state-hidden");
                lpauth.onAuthReady(() => toggleTriggerElement.classList.remove("mainNav__userAccountItem--state-hidden"));
            } else {
                toggleTriggerElement.classList.remove("mainNav__userAccountItem--state-hidden");
            }

            this._domDocument.addEventListener("click", (e) => {
                const authReady = lpauth ? lpauth.isAuthReady() : true;

                if (toggledElement.contains(e.target) || !authReady) {
                    return;
                }

                if (toggleTriggerElement.contains(e.target)) {
                    toggleTriggerElement.classList.toggle("mainNav__userAccountItem--state-open");
                } else {
                    toggleTriggerElement.classList.remove("mainNav__userAccountItem--state-open");
                }
            });
        }
    }
}
