
/**
 * @typedef {import("../Analytics").default} Analytics
 */

export default class HandlerAbstract {

    /**
     * @protected
     * @var {Analytics}
     */
    _analytics;

    /**
     * @protected
     * @var {object}
     */
    _domWindow;

    /**
     * @protected
     * @var {object}
     */
    _domDocument;

    /**
     * @protected
     * @var {string}
     */
    _scopeSelector = "";

    constructor(scopeSelector = null) {
        if (scopeSelector) {
            this._scopeSelector = scopeSelector;
        }
    }

    /**
     * @param window
     * @param {Analytics} analytics
     */
    init(window,analytics) {
        this._analytics = analytics;
        this._domWindow = window;
        this._domDocument = window.document;
    }

    getEventHandlers() {
    }

    /**
     * @param {Event} evt
     * @return {boolean}
     */
    checkScope(evt) {
        const target = evt.target;

        if (!this._scopeSelector) {
            return true;
        }

        if (!!target) {
            return !!target.closest(this._scopeSelector) || target.matches(this._scopeSelector);
        } else {
            return false;
        }
    }

    /**
     * @param {Event} evt
     * @return {boolean}
     */
    shouldHandleEvent(evt) {
        const methodName = "shouldHandle" + evt.type[0].toUpperCase() + evt.type.slice(1);
        return this[methodName] !== undefined ? this[methodName].call(this, evt) : this.checkScope(evt);
    }

    /**
     * @param {Event} evt
     * @return {boolean}
     */
    handle(evt) {
        const methodName = "handle" + evt.type[0].toUpperCase() + evt.type.slice(1);
        return this[methodName] !== undefined ? this[methodName].call(this, evt) : true;
    }

    getTargetLabel(target) {
        return target.title?.trim() ||
          target.textContent?.trim().replace(/\s+/g, " ") ||
          target.querySelector("img[alt]:not([alt=''])")?.getAttribute("alt")?.trim() ||
          "";
    }
}
