import Cookies from "js-cookie";

export default class WebPartValidator {

    /** @type Element */
    _node;

    constructor(node) {
        this._node = node;
    }

    isValid() {
        return this._validateCrayon();
    }

    _validateCrayon() {
        if (this._node.getAttribute("data-webpart-validate-crayon") !== "true") {
            return true;
        }

        return Cookies.get("CrayonsViewSwitch") === "active";
    }
}
