
export default class HandlersManager {

    /**
     * @private
     * @var {object}
     */
    _domWindow = null;

    /**
     * @private
     * @var {object}
     */
    _analytics = null;

    /**
     * @private
     * @var {array} 
     */
    _analyticsEventHandlers = [];

    /**
     * @private
     * @var {object.<string, HandlerAbstract>}
     */
    _domEventsHandlers = {};

    /**
     * @private
     * @var {object.<string, function>}
     */
    _domEventsCallbacks = {};

    /**
     * @private
     * @var {boolean}
     */
    _started = false;

    /**
     * @type {boolean}
     * @private
     */
    _dirty = true;

    constructor(window) {
        this._domWindow = window;

        this._analytics = this._domWindow.nuglif.analytics;
    }

    /**
     * @param {array} handlers 
     */
    addHandlers(handlers, priority = 0) {
        if (!Array.isArray(handlers)) {
            handlers = [handlers];
        }

        handlers = handlers.map(handler => ({ handler, priority }));

        this._analyticsEventHandlers.push(...handlers);
        this._dirty = true;

        if (this._started)
            this.startTracking();
    }

    startTracking() {

        if(this._started || Object.keys(this._domEventsCallbacks).length) {
            this.stopTracking();
        }

        if (this._shouldSetupHandlers()) {
            this._setUpHandlers();
        }

        for (let domEvent in this._domEventsHandlers) {
            if (this._domEventsHandlers.hasOwnProperty(domEvent)) {
                this._domEventsCallbacks[domEvent] = this._handleDomEvent.bind(this, domEvent);
                this._domWindow.addEventListener(domEvent, this._domEventsCallbacks[domEvent]);
            }
        }
        
        this._started = true;

    }

    stopTracking() {
        for (let domEvent in this._domEventsHandlers) {
            if (this._domEventsHandlers.hasOwnProperty(domEvent)) {
                this._domWindow.removeEventListener(domEvent, this._domEventsCallbacks[domEvent]);
                delete this._domEventsCallbacks[domEvent];
                delete this._domEventsHandlers[domEvent];
            }
        }
        this._started = false;
    }

    _shouldSetupHandlers() {
        return !Object.keys(this._domEventsHandlers).length || this._dirty;
    }

    /**
     * @private
     */
    _setUpHandlers() {
        let handlers = this._analyticsEventHandlers.sort((h1, h2) => h1.priority - h2.priority);

        handlers.forEach((handlerWrapper) => {
            const handler = handlerWrapper.handler;
            handler.init(this._domWindow, this._analytics);
            let handledEvents = [].concat(handler.getEventHandlers());

            handledEvents.forEach((domEvent) => {
                let handlers = this._domEventsHandlers[domEvent] || [];
                handlers.push(handler);
                this._domEventsHandlers[domEvent] = handlers;
            });
        });
        this._dirty = false;
    }

    /**
     * @private
     * @param {string} evtName 
     * @param {Event} evt 
     */
    _handleDomEvent(evtName, evt) {
        let shouldStop = false;
        let i = 0;
        while (!shouldStop) {
            if (this._domEventsHandlers[evtName][i].shouldHandleEvent(evt)) {
                shouldStop = this._domEventsHandlers[evtName][i].handle(evt) === false;
            }
            shouldStop = shouldStop || i >= this._domEventsHandlers[evtName].length - 1;
            i++;
        }
    }

}
