import WebPartValidator from "./WebPartValidator";

export default class WebPart {

    /**
     * @var {Element}
     * @private
     */
    _node;

    /**
     * @var {boolean}
     * @private
     */
    _appendMode;

    /**
     * @var {Document}
     * @private
     */
    _document;

    /**
     * @var {Function}
     * @private
     */
    _onLoaded;

    /**
     * @var {Boolean}
     * @private
     */
    _recursive;

    /**
     * workaround to make the class testable
     * @type function
     * @private
     */
    _eval = eval; // eslint-disable-line no-eval

    /**
     * @param {Document} document
     * @param {Element} node
     * @param {Function} onLoaded
     * @param {boolean} append
     * @param {boolean} recursive
     */
    constructor(document, node, onLoaded = () => {}, append = false, recursive = false) {
        this._document = document;
        this._node = node;
        this._onLoaded = onLoaded;
        this._appendMode = append;

        this._recursive = recursive;
    }

    /**
     * @param {Object<string>} params
     */
    load(params = {}) {
        let url = this._getWebPartUrl();

        if (!new WebPartValidator(this._node).isValid()) {
            return;
        }

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

        return fetch(url.toString())
            .then((res) => {
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res.text();
            })
            .then(data => {
                return this._render(data);
            })
            .then(() => {
                this._onLoaded();
            })
            .catch(() => this._onLoaded());
    }

    /**
     * @param {string} data
     * @private
     */
    _render(data) {
        if (this._appendMode)
            this._node.innerHTML += data;
        else
            this._node.innerHTML = data;

        let renderingPromise = null;

        const scripts = this._node.querySelectorAll("script");
        if (scripts && scripts.length) {
            const scriptResults = Array.from(scripts).map(script => { return this._eval(script.innerText); });
            renderingPromise = Promise.all(scriptResults);
        } else {
            renderingPromise = Promise.resolve();
        }

        if (this._recursive) {
            const childWebParts = this._node.querySelectorAll(".webpart");
            return renderingPromise.then(() => Promise.all(Array.from(childWebParts).map(webPart => {
                return (new WebPart(
                    this._document,
                    webPart,
                    () => {},
                    false,
                    true
                )).load();
            })));
        }

        return renderingPromise;
    }

    /**
     * @returns {URL}
     * @private
     */
    _getWebPartUrl() {
        let dataUrl = this._node.getAttribute("data-webpart-url");
        if (!dataUrl.match(/^https?:\/\//)) {
            if (dataUrl[0] === "/") {
                dataUrl = this._document.location.protocol + "//" + this._document.location.hostname + (this._document.location.port ? ":" + this._document.location.port : "") + dataUrl;
            } else {
                dataUrl = this._document.location.href.substring(0, this._document.location.href.lastIndexOf("/")) + "/" + dataUrl;
            }
        }

        return new URL(dataUrl);
    }

}
